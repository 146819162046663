import {Link} from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Header = () => (
    <header>
        <Wrapper>
            <Link to='/'>
                <div style={{position: 'absolute', bottom: '0', color: '#074770',}}>
                <h1 style={{display: 'inline-block', fontFamily: 'Helvetica',}}>index</h1>
                <span style={{fontSize: '12px', marginLeft: '20px'}}>文学賞受賞作品検索</span>
                </div>
            </Link>
        </Wrapper>
    </header>
)

const Wrapper = styled.div`
    height: 100px;
    color: #074770;
    border-bottom: solid 5px;
    margin: 0 3% 10px 3%;
    position: relative;
`

export default Header
