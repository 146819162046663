import React from 'react'
import {Helmet} from 'react-helmet'
import {graphql, useStaticQuery} from 'gatsby'
import {useLocation} from "@reach/router"


const SEO = ({title, description, image, article}) => {
    const {site} = useStaticQuery(query)
    const {pathname} = useLocation()
    const {
        defaultTitle,
        defaultDescription,
        siteUrl,
        defaultImage,
        twitterUsername,
    } = site.siteMetadata
    const seo = {
        title: title ? `${title} | ${defaultTitle}` : defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname}`,
    }

    return (
        <Helmet>
            <html lang="ja"/>
            <title>{seo.title}</title>
            <meta name="description" content={seo.description}/>
            <meta name="image" content={seo.image}/>
            {seo.url && <meta property="og:url" content={seo.url}/>}
            {(article ? true : null) && <meta property="og:type" content="article"/>}
            {seo.title && <meta property="og:title" content={seo.title}/>}
            {seo.description && (
                <meta property="og:description" content={seo.description}/>
            )}
            {seo.image && <meta property="og:image" content={seo.image}/>}
            <meta name="twitter:card" content="summary_large_image"/>
            {twitterUsername && (
                <meta name="twitter:creator" content={twitterUsername}/>
            )}
            {seo.title && <meta name="twitter:title" content={seo.title}/>}
            {seo.description && (
                <meta name="twitter:description" content={seo.description}/>
            )}
            {seo.image && <meta name="twitter:image" content={seo.image}/>}
            <script data-ad-client="ca-pub-6916517384982022" async
                    src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
        </Helmet>
    )
}

export default SEO

const query = graphql`
  query {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`