import React from "react"
import {useStaticQuery, graphql} from "gatsby"

import Header from './header'
import Footer from './footer'
import './layout.css'
import 'semantic-ui-css/semantic.min.css'
import './style.css'

const Layout = ({children}) => {
    //   const data = useStaticQuery(graphql`
    //   query SiteTitleQuery {
    //     site {
    //       siteMetadata {
    //         title
    //       }
    //     }
    //   }
    // `)

    return (
        <>
            <Header/>
            <div style={{margin: `0 auto`, maxWidth: 960, padding: `0 1.0875rem 1.45rem`}}>
                <main>{children}</main>
                <Footer/>
            </div>
        </>
    )
}

export const Grid = ({children, ncol}) => (
    <div className='ui centered grid'>
        <div className={`${ncol} wide column`}>
            {children}
        </div>
    </div>
)

export default Layout
