import {Link} from 'gatsby'
import React from 'react'
import styled from 'styled-components'

const Footer = () => (
    <Wrapper>
        <hr width='80%' color='#ccc'/>
        <i className='copyright outline icon'></i>
        index {(new Date()).getFullYear()}
        <Link to={'/about'} style={{color: '#000', marginLeft: '30px'}}>about</Link>
        <Link to={'/disclaimer'} style={{color: '#000', marginLeft: '30px'}}>免責事項</Link>
        <Link to={'/privacy'} style={{color: '#000', marginLeft: '30px'}}>プライバシーポリシー</Link>
    </Wrapper>
)

const Wrapper = styled.footer`
    line-height: 50px;
    text-align: center;
    margin-top: 50px;
    font-size: 12px;
`

export default Footer
